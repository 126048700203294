@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/breakpoints';

html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    touch-action: manipulation;
    box-sizing: border-box;
    font-family: 'helvetica neue', 'helvetica', 'arial', sans-serif;
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
    margin: 1.75em;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

p,
figure {
    margin: 0;
}

img {
    max-width: 100%;
}

button {
    cursor: pointer;
    appearance: none;
    border: none;
    background-color: transparent;
}

a {
    color: #44f;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

* {
    outline: none;
}
